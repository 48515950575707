/*
! README
! Todos os endpoints que tratam dados que afetam todo o sistema estarão em actions, para maior controle de estado e fluxo mais limpo
*/
import router from "@/router";

import {
  serviceCondominio,
  servicePontoInstalacao,
  serviceSensores,
  serviceTipos,
  serviceUser,
} from "@/service";

export default {
  deslogarUserAndRemoverDados({ commit }) {
    commit("_unsetCondominio", null);
    commit("setPontosDeInstalacao", []);
    commit("setSensores", []);
    commit("setCondominios", []);
    commit("setUser", {});
    localStorage.removeItem("JWT_LENTEC_WEB");
    router.push("/login");
  },
  selecionarCondominio({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      if (!payload) {
        console.error("Sem dados no payload do selecionarCondominio");
        reject({ data: "Sem dados no payload do selecionarCondominio" });
      }
      commit("selectCondominio", payload);
      dispatch("loadPontosDeInstalacao");
      dispatch("loadSensores")
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadPontosDeInstalacao({ commit }) {
    return new Promise((resolve, reject) => {
      servicePontoInstalacao
        .getPontosDeInstalacao()
        .then((resp) => {
          commit("setPontosDeInstalacao", resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadSensores({ commit }) {
    return new Promise((resolve, reject) => {
      serviceSensores
        .getSensoresDoCondominio()
        .then((resp) => {
          commit("setSensores", resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadUser({ commit }) {
    return new Promise((resolve, reject) => {
      serviceUser
        .getDadosUser()
        .then((resp) => {
          commit("setUser", resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadDados({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      dispatch("loadTypes");
      dispatch("loadUser")
        .then(() => {
          dispatch("loadCondominios")
            .then((resp) => {
              commit("setCondominios", resp.data);
              resolve(resp);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadTypes({ dispatch }) {
    dispatch("loadTypesRoles");
    dispatch("loadTypesEquipamentos");
    dispatch("loadTypesSensores");
  },
  loadCondominios({ commit }) {
    return new Promise((resolve, reject) => {
      serviceCondominio
        .buscarCondominios()
        .then((resp) => {
          commit("setCondominios", resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadTypesSensores({ commit }) {
    serviceTipos
      .getSensores()
      .then((resp) => {
        commit("setTypeSensores", resp.data);
      })
      .catch((err) => {
        console.error(err);
      });
  },
  loadTypesEquipamentos({ commit }) {
    serviceTipos
      .getEquipamentos()
      .then((resp) => {
        commit("setTypeEquipamentos", resp.data);
      })
      .catch((err) => {
        console.error(err);
      });
  },
  loadTypesRoles({ commit }) {
    // não utilizado por hora...
    serviceTipos
      .getRoles()
      .then((resp) => {
        commit("setTypeRoles", resp.data);
      })
      .catch((err) => {
        console.error(err);
      });
  },
};
