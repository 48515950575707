<template>
  <v-btn color="primary" depressed class="tw-text-primary" large>
    <span class="tw-mr-2" v-if="icon">
      <v-icon size="20">mdi-{{ icon }}</v-icon>
    </span>
    <div class="tw-text-sm tw-font-bold tw-capitalize">
      <slot />
    </div>
  </v-btn>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
    },
  },
};
</script>
