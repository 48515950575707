import axios from "axios";

export default {
  esqueciMinhaSenha(email) {
    return axios.post("/v1/auth/esqueci-minha-senha", {
      username: email,
    });
  },
  alterarSenha(payload) {
    return axios.post("/v1/auth/alterar-senha", {
      codigo: payload.codigo,
      email: payload.email,
      novaSenha: payload.novaSenha,
    });
  },
  confirmarCadastroCliente(codigo) {
    return axios.get(`/v1/usuario/codigo-finalizacao-cadastro/${codigo}`);
  },
  async login(email, senha) {
    return await axios.post("/v1/auth/login", {
      continuarLogado: true,
      password: senha,
      username: email,
    });
  },
};
