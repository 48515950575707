import store from "@/store";
import axios from "axios";

export default {
  getPontosDeInstalacao() {
    let condominioId = store.getters["dashboard/getCondominioSelecionado"];
    return axios.get(`/v1/pontoinstalacao/condominio/${condominioId}`);
  },
  editarPontoInstalacao(payload) {
    let condominioId = store.getters["dashboard/getCondominioSelecionado"];
    return axios.post("/v1/pontoinstalacao", {
      condominioId: condominioId,
      descricao: payload.descricao,
      id: payload.id,
    });
  },
  removerPontoDeInstalacao(idPontoDeInstalacao) {
    return axios.delete(`/v1/pontoinstalacao/${idPontoDeInstalacao}`);
  },
  novoPontoInstacao(payload) {
    let condominioId = store.getters["dashboard/getCondominioSelecionado"];
    return axios.post("/v1/pontoinstalacao", {
      condominioId: condominioId,
      descricao: payload,
    });
  },
};
