import axios from "axios";

export default {
  activeUser(code) {
    return axios.post(`/v1/public/ativar-cliente/${code}`);
  },
  createUserCliente(payload) {
    return axios.post("/v1/public/novo-cliente", payload);
  },
  uploadFoto(dataForm_file) {
    return axios.post("/v1/usuario/upload-foto", dataForm_file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getUserById(userID) {
    return axios.get(`/v1/usuario/${userID}`);
  },
  getDadosUser() {
    return axios.get("/v1/usuario/me");
  },
  confirmarCodigoDoCliente(code) {
    return axios.post(`/v1/public/ativar-cliente/${code}`);
  },
  novoUsuarioPlataforma(payload) {
    return axios.post(`/v1/usuario`, {
      email: payload.email,
      fotoUrl: null,
      nome: payload.nome,
      role: payload.role,
      senha: payload.senha,
    });
  },
  editarUsuarioPlataforma(payload) {
    return axios.post("/v1/usuario/update", {
      id: payload.id,
      mensagem: "",
      nome: payload.nome,
      role: payload.role,
    });
  },
  removerUsuario(id) {
    return axios.delete(`/v1/usuario/${id}`);
  },
  updateUsuario(payload) {
    return axios.post("/v1/usuario/update", payload);
  },
  updatePerfilCliente(payload) {
    return axios.post(`/v1/usuario/update-profile-client`, payload);
  },
  updatePerfilAdmin(payload) {
    return axios.post(`/v1/usuario/update-profile-admin`, payload);
  },
  paginationCliente(page, pagesize, filtros) {
    return axios.post(`/v1/usuario/pagination-client`, {
      descending: filtros.sortDesc,
      direction: "ASC",
      filtros: {
        email: filtros.email,
        id: 0,
        mensagem: "",
        nome: filtros.nome,
        role: filtros.role,
      },
      pageNo: page,
      pageSize: pagesize,
      sortBy: filtros.sortBy,
    });
  },
  getUsers(page, pagesize, filtros) {
    return axios.post(`/v1/usuario/pagination`, {
      descending: filtros.sortDesc,
      direction: "ASC",
      filtros: {
        email: filtros.email,
        id: 0,
        mensagem: "",
        nome: filtros.nome,
        role: filtros.role,
      },
      pageNo: page,
      pageSize: pagesize,
      sortBy: filtros.sortBy,
    });
  },
};
