/* eslint-disable no-undef */
/* eslint-disable no-console */
import { register } from "register-service-worker";
import axios from "axios";

var swRegistration = null;
// eslint-disable-next-line prettier/prettier
const publicSigningKey ='BAyW-XC81fjHX25hhGAvDgSaVaWWgh2nYgB9S6mjzq4AWMPGNnbC4rw1mjVENoUYfbJ_z97D--Ah1j_ppJzw5WI'

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered() {
      console.log("Service Worker is registered");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated() {
      console.log("New content is available; please refresh.");
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}

//register the service worker, register our push api, sedn the notifation
export async function enableWebPushNotification() {
  swRegistration = await navigator.serviceWorker.register(
    `${process.env.BASE_URL}worker.js`,
    {
      scope: "/",
    }
  );
  //check if the service worker can work in the current browser
  if (Notification.permission === "denied") {
    console.log("Usuário não permitiu o push notification ;(");
    return;
  }

  if (Notification.permission === "default") {
    init();
  }
}

async function init() {
  subscribe(publicSigningKey);
}

function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

async function subscribe(publicSigningKey) {
  const applicationServerKey = urlB64ToUint8Array(publicSigningKey);
  const subscription = await swRegistration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: applicationServerKey,
  });

  axios.post("/v1/push/subscribe", subscription);
}
