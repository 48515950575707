<template>
  <div class="tw-p-1 tw-min-h-[20px] tw-items-center">
    <div
      class="tw-font-normal tw-text-xs tw-text-red-500"
      v-if="validate.$dirty"
    >
      <template>
        <div v-if="rules.includes('required') && !validate.required">
          <div class="tw-normal-case">Campo {{ label }} é obrigatório.</div>
        </div>
        <div v-else>
          <div v-if="rules.includes('email') && !validate.email">
            Isso não se parece com um email
          </div>
          <div v-if="rules.includes('minLength') && !validate.minLength">
            Deve conter no mínimo {{ min }} caracteries.
          </div>
          <div
            v-if="rules.includes('sameAsPassword') && !validate.sameAsPassword"
          >
            {{ label }} não confere com {{ same }}
          </div>
          <div v-if="rules.includes('minValue') && !validate.minValue">
            {{ label }} deve conter conter no mínimo {{ min }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "vErrorInput",
  props: {
    validate: {
      required: true,
      type: Object,
    },
    rules: {
      required: true,
      type: Array(),
    },
    label: {
      required: true,
      type: String,
    },
    same: {
      type: String,
    },
    min: {
      type: Number,
    },
  },
  computed: {},
};
</script>

<style></style>
