export default {
  getNotificacoesContagem(state) {
    return state.notificacoes.length;
  },
  getNotificacoes(state) {
    return state.notificacoes;
  },
  getRoles(state) {
    return state.typesRoles;
  },
  getTypesRoles(state) {
    return state.typesRoles;
  },
  getTypesSensores(state) {
    return state.typesSensores;
  },
  getTypesEquipamentos(state) {
    return state.typesEquipamentos;
  },
  getRolesListId(state) {
    let arrayResponse = [];
    state.typesRoles.forEach((element, index) => {
      arrayResponse[index] = element.id;
    });
    return arrayResponse;
  },
  getPontosDeInstalacao(state) {
    return state.pontosDeInstalacao;
  },
  getSensores(state) {
    return state.sensores;
  },
  getCondominios(state) {
    return state.condominios;
  },
  getCondominioSelecionado(state) {
    return state.condominioSelecionado;
  },
  getUserRole(state) {
    return state.user.role;
  },
  getUser(state) {
    return state.user;
  },
};
