import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import moduleDash from "./dashboard/index";

export default new Vuex.Store({
  state: {
    notifyList: [],
  },
  getters: {
    getNotifyList(state) {
      return state.notifyList;
    },
    getNotify: (state) => (idx) => {
      return state.notifyList.find(idx);
    },
  },
  mutations: {
    success(state, payload) {
      state.notifyList.push({
        tipo: "success",
        mensagem: payload,
      });
    },
    error(state, payload) {
      state.notifyList.push({
        tipo: "error",
        mensagem: payload,
      });
    },
    warning(state, payload) {
      state.notifyList.push({
        tipo: "warning",
        mensagem: payload,
      });
    },
    info(state, payload) {
      state.notifyList.push({
        tipo: "info",
        mensagem: payload,
      });
    },
    setNotify(state, payload) {
      state.notifyList.push(payload);
    },
    removeNotify(state, indexPayload) {
      state.notifyList.splice(indexPayload, 1);
    },
  },
  modules: {
    dashboard: moduleDash,
  },
});
