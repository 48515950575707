import axios from "axios";

export default {
  novoEquipamento(descricao) {
    return axios.post("/v1/tipo-equipamento", {
      descricao: descricao,
      id: null,
      icon: "",
    });
  },
  alterarEquipamentos(id, descricao) {
    return axios.post(`/v1/tipo-equipamento/${id}`, {
      descricao: descricao,
      id: id,
      icon: "",
    });
  },
  removerEquipamento(equipamentoID) {
    return axios.delete(`/v1/tipo-equipamento/${equipamentoID}`);
  },
};
