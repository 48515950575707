import Vue from "vue";
import VueRouter from "vue-router";
import vDashboard from "./dash";

/*
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated") throw err;
  });
};*/

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "root",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/cadastro",
    name: "cadastro-cliente",
    component: () => import("../views/cadastroCliente.vue"),
  },
  {
    path: "/user/ativar-conta/:id",
    name: "ativarConta",
    component: () => import("../views/ativarCadastroCliente.vue"),
  },
  {
    path: "/esqueci-minha-senha/:email?/:codigo?",
    name: "esqueciMinhaSenha",
    component: () => import("../views/esqueciMinhaSenha.vue"),
  },
  ...vDashboard,
  {
    path: "*",
    name: "NaoEncontrada",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
