<template>
  <button
    class="tw-font-semibold tw-w-full tw-p-3 tw-rounded tw-text-[#64748B] tw-bg-white hover:tw-bg-gray-100"
  >
    <slot />
  </button>
</template>

<script>
export default {};
</script>

<style></style>
