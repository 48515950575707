import axios from "axios";

export default {
  incluir(descricao) {
    return axios.post("/v1/tipos-sensor/novo", {
      descricao: descricao,
      id: null,
      icon: "",
    });
  },
  editar(_sensorId, _descricao) {
    return axios.post(`/v1/tipos-sensor/update`, {
      id: _sensorId,
      descricao: _descricao,
    });
  },
  remover(sensorId) {
    return axios.delete(`/v1/tipos-sensor/${sensorId}`);
  },
};
