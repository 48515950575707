var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-inline-block tw-bg-white tw-w-full tw-shadow-lg tw-border tw-border-solid tw-border-gray-200 tw-rounded-md tw-p-4 tw-mb-1 tw-transition-all",class:[
    {
      'tw-opacity-0 translate-x-64 tw-relative': _vm.animationClose,
      'tw-opacity-100': !_vm.animationClose,
    },
    _vm.getBgType,
  ]},[_c('div',{staticClass:"tw-relative"},[_c('div',{staticClass:"tw-absolute tw--mt-2 tw-right-0"},[_c('v-icon',{attrs:{"color":"red","size":"20"},on:{"click":function($event){return _vm.close()}}},[_vm._v("mdi-close")])],1)]),_c('div',{staticClass:"tw-font-bold tw-text-sm",class:_vm.getTextType},[_vm._v(" "+_vm._s(_vm.getTitleType)+" ")]),_c('div',{staticClass:"tw-font-normal tw-pt-2 tw-text-sm",class:_vm.getTextType},[_vm._v(" "+_vm._s(_vm.item.mensagem)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }