import axios from "axios";

export default {
  createLote({ quantidadeSensor, tipoSensorId }) {
    return axios.post("/v1/lote", {
      quantidadeSensor: quantidadeSensor,
      tipoSensorId: tipoSensorId,
    });
  },
  getLote(loteId) {
    return axios.get(`/v1/lote/${loteId}`);
  },
  removerLote(loteID) {
    return axios.delete(`/v1/lote/${loteID}`);
  },
  paginationLote(page, pageSize, filtros) {
    return axios.post("/v1/lote/pagination", {
      descending: true,
      direction: "DESC",
      filtros: {
        /*id: 0,
        loteId: 0,
        mensagem: "string",*/
      },
      pageNo: page,
      pageSize: pageSize,
      sortBy: filtros.sortBy, //"dataCadastro",
    });
  },
};
