<template>
  <div
    :class="[
      {
        'tw-opacity-0 translate-x-64 tw-relative': animationClose,
        'tw-opacity-100': !animationClose,
      },
      getBgType,
    ]"
    class="tw-inline-block tw-bg-white tw-w-full tw-shadow-lg tw-border tw-border-solid tw-border-gray-200 tw-rounded-md tw-p-4 tw-mb-1 tw-transition-all"
  >
    <div class="tw-relative">
      <div class="tw-absolute tw--mt-2 tw-right-0">
        <v-icon color="red" size="20" @click="close()">mdi-close</v-icon>
      </div>
    </div>
    <div class="tw-font-bold tw-text-sm" :class="getTextType">
      {{ getTitleType }}
    </div>
    <div class="tw-font-normal tw-pt-2 tw-text-sm" :class="getTextType">
      {{ item.mensagem }}
    </div>
  </div>
</template>

<script>
export default {
  name: "NotifyItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      timer: 0,
      animationClose: false,
    };
  },
  mounted() {
    this.createTimerCard();
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  computed: {
    getTitleType() {
      if (this.item.tipo == "error") return "Ocorreu um erro";
      else if (this.item.tipo == "warning") return "Aviso";
      else if (this.item.tipo == "success") return "Tudo certo";
      return "Informativo";
    },
    getTextType() {
      if (this.item.tipo == "error") return "tw-text-red-800";
      else if (this.item.tipo == "warning") return "tw-text-amber-600";
      else if (this.item.tipo == "success") return "tw-text-emerald-600";
      return "tw-text-black";
    },
    getBgType() {
      if (this.item.tipo == "error") return "tw-bg-red-50";
      if (this.item.tipo == "warning") return "tw-bg-amber-50";
      if (this.item.tipo == "success") return "tw-bg-emerald-50";
      return "tw-bg-white";
    },
  },
  methods: {
    callEndTimer() {
      this.close();
    },
    createTimerCard() {
      this.timer = setTimeout(this.callEndTimer, 5000);
    },
    close() {
      this.animationClose = true;
      setTimeout(() => {
        this.$store.commit("removeNotify", this.index);
      }, 500);
    },
  },
};
</script>

<style></style>
