import axios from "axios";

export default {
  getRoles() {
    return axios.get("/v1/tipos/roles");
  },
  getEquipamentos() {
    return axios.get("/v1/tipos/tipo-equipamento");
  },
  getSensores() {
    return axios.get("/v1/tipos/tipo-sensor");
  },
};
