import Vue from "vue";
import Vuetify from "vuetify/lib";
import VueI18n from "vue-i18n";
import en from "./i18/en";
import ptbr from "./i18/pt-br";

Vue.use(Vuetify);
Vue.use(VueI18n);

const messages = {
  en: {
    $vuetify: {
      ...en,
    },
  },
  pt: {
    $vuetify: {
      ...ptbr,
    },
  },
};

import theme from "./thema";

Vue.use(Vuetify);

const i18n = new VueI18n({
  locale: "pt", // set locale
  fallbackLocale: "en",
  messages, // set locale messages
});

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: theme.primary,
        secondary: theme.secondary,
      },
      dark: {
        primary: theme.primary,
        secondary: theme.secondary,
      },
    },
  },
});
