import axios from "axios";

export default {
  novoCondominio(condominioNome) {
    return axios.post("/v1/condominio", {
      name: condominioNome,
    });
  },
  alterarCondominio(condominioId, condominioNome) {
    return axios.put("/v1/condominio", {
      id: condominioId,
      name: condominioNome,
    });
  },
  buscarCondominios() {
    return axios.get("/v1/condominio/"); // validar essa / no final
  },
  buscarCondominioPorId(condominioId) {
    return axios.get(`/v1/condominio/${condominioId}`);
  },
  removerCondominio(condominioId) {
    return axios.delete(`/v1/condominio/${condominioId}`);
  },
  addConvidado(condominioId, email) {
    return axios.post(`/v1/condominio/${condominioId}/add-convidado`, {
      emailConvidado: email,
    });
  },
  getAllConvidado(condominioId) {
    return axios.get(`/v1/condominio/${condominioId}/get-convidado`);
  },
  deleteConvidado(condominioId, email) {
    return axios.post(`/v1/condominio/${condominioId}/delete-convidado`, {
      emailConvidado: email,
    });
  },
};
