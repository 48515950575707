export default {
  setPontosDeInstalacao(state, payload) {
    state.pontosDeInstalacao = payload;
  },
  setTypeRoles(state, payload) {
    state.typesRoles = payload;
  },
  setTypeEquipamentos(state, payload) {
    state.typesEquipamentos = payload;
  },
  setTypeSensores(state, payload) {
    state.typesSensores = payload;
  },
  setSensores(state, payload) {
    state.sensores = payload;
  },
  setUser(state, payload) {
    state.user = payload;
  },
  _unsetCondominio(state) {
    state.condominioSelecionado = null;
  },
  selectCondominio(state, condominioId) {
    if (condominioId) {
      state.condominios.find((item) => {
        if (item.condominioId == condominioId) {
          state.condominioSelecionado = condominioId;
        }
      });
    } else {
      throw Error("Condominio não encontrado na lista interna");
    }
  },
  setCondominios(state, listaCondominios) {
    state.condominios = listaCondominios;
  },
};
