<template>
  <button
    class="tw-font-semibold tw-text-white tw-text-opacity-80 tw-w-full tw-bg-primary tw-bg-opacity-60 hover:tw-bg-opacity-70 tw-p-3 tw-inline-flex tw-rounded tw-text-center tw-items-center"
  >
    <div class="tw-flex tw-flex-row tw-w-full">
      <div class="tw-flex-none tw-pr-1">
        <span v-if="!loading">
          <v-icon>{{ icone }}</v-icon>
        </span>
        <span v-else>
          <v-icon class="tw-animate-spin">mdi-loading</v-icon>
        </span>
      </div>
      <div class="tw-flex-1">
        <slot />
      </div>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    icone: {
      default: "mdi-check",
      required: false,
      type: String,
    },
    loading: {
      type: Boolean,
    },
  },
};
</script>

<style></style>
