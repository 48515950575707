<template>
  <div class="tw-relative">
    <slot />
    <div
      class="tw-absolute tw-inline-block tw--ml-7 tw-mt-[6px] tw-text-gray-400"
      v-if="lock"
    >
      <v-icon size="20">mdi-lock</v-icon>
    </div>
  </div>
</template>

<script>
// EM CONSTRUÇÃO
// COMPORTAMENTO ESPERADO: RENDERIZAR O LOCK SEM ERROS DE LAYOUT E SEM ERROS EM FUNCIONALIDADES
// EXTENÇÃO DO MESMO: PODER USAR QUALQUER ICONE E QUALQUER COR
export default {
  props: {
    lock: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
