import axios from "axios";

export default {
  getNotificationId(notificacaoID) {
    return axios.get(`/v1/notificacao/${notificacaoID}`);
  },
  getCountNotReadNotifications(userID, midiaID) {
    return axios.get(
      `/v1/notificacao/usuario/${userID}/count-not-read/${midiaID}`
    );
  },
  getLastNotifications(userID, midiaID) {
    return axios.get(`/v1/notificacao/usuario/${userID}/last/${midiaID}`);
  },
};
