import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./style/index.css";
import "./style/base.scss";
import Vuelidate from "vuelidate";
import "./globalComponents.js";
import axios from "axios";
import VueTheMask from "vue-the-mask";
import makeServer from "@/mirage/mirage";
import getBaseUrl from "./const/baseUrl";
import moment from "moment";

// ! atenção, isso fará o banco ser um mock quando TRUE e estando em development
const mirageToggle = false;

if (process.env.NODE_ENV === "development" && mirageToggle) {
  //localStorage.removeItem("JWT_LENTEC_WEB");
  makeServer();
}

moment.locale("pt-br");

Vue.config.productionTip = false;
Vue.use(VueTheMask);
Vue.use(Vuelidate);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

const URL_BASE = getBaseUrl();
axios.defaults.baseURL = URL_BASE;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status == 401) {
      let routerPath = router.currentRoute.path;
      if (routerPath === "/login") {
        return Promise.reject("Usuário ou senha inválido");
      } else {
        console.log("Sessão expirada");
        if (routerPath !== "/esqueci-minha-senha") {
          router.push("/login");
          return Promise.reject("Sua sessão expirou");
        }
      }
    }
    return Promise.reject(error.response);
  }
);

axios.interceptors.request.use(
  (config) => {
    if (
      config.baseURL === URL_BASE &&
      !config.headers.Authorization &&
      localStorage.getItem("JWT_LENTEC_WEB")
    ) {
      let token = localStorage.getItem("JWT_LENTEC_WEB");
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
