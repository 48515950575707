import axios from "axios";

export default {
  getCEP(cep) {
    return axios.get(`/v1/cep/${cep}`);
  },
  getEstados() {
    return axios.get("/v1/estado");
  },
  getEstadoPorId(id) {
    return axios.get(`/v1/estado/${id}`);
  },
  getCidadesPorEstado(estadoID) {
    return axios.get(`/v1/cidade/uf/${estadoID}`);
  },
};
