export default {
  typesRoles: [],
  typesEquipamentos: [],
  typesSensores: [],
  pontosDeInstalacao: [],
  sensores: [],
  condominios: [],
  condominioSelecionado: {},
  user: {},
  notificacoes: [],
};
