import store from "@/store";
import axios from "axios";

export default {
  novoSensor(payload) {
    let condominioId = store.getters["dashboard/getCondominioSelecionado"];
    return axios.post("/v1/sensor", {
      condominioId: condominioId,
      equipamentoId: null, //payload.equipamentoId,
      numeroSerie: payload.numeroSerie,
      pontoInstalacaoDescricao: payload.pontoInstalacaoDescricao,
      pontoInstalacaoId: payload.pontoInstalacaoId, //payload.pontoInstalacaoId,
      tipoEquipamentoId: payload.tipoEquipamentoId,
      tipoSensorId: payload.tipoSensorId,
      totalLitrosEquipamento: payload.totalLitrosEquipamento,
      profundidade: payload.profundidade,
    });
  },
  editarSensor(payload) {
    let condominioId = store.getters["dashboard/getCondominioSelecionado"];
    return axios.post("/v1/sensor", {
      condominioId: condominioId,
      equipamentoId: payload.equipamentoId,
      id: payload.id,
      numeroSerie: payload.numeroSerie,
      pontoInstalacaoDescricao: payload.pontoInstalacaoDescricao,
      pontoInstalacaoId: payload.pontoInstalacaoId,
      tipoEquipamentoId: payload.tipoEquipamentoId,
      tipoSensorId: payload.tipoSensorId,
      totalLitrosEquipamento: payload.totalLitrosEquipamento,
      profundidade: payload.profundidade,
    });
  },
  getSensorId(sensorId) {
    return axios.get(`/v1/sensor/${sensorId}`);
  },
  removerSensor(sensorID) {
    return axios.delete(`/v1/sensor/${sensorID}`);
  },
  getSensoresDoCondominio() {
    let condominioId = store.state.dashboard.condominioSelecionado;
    return axios.get(`/v1/sensor/condominio/${condominioId}`);
  },
  definirAlerta(sensorID, valor, flagInverter) {
    return axios.post(`/v1/sensor/${sensorID}/alerta`, {
      valor: valor,
      flagInverter: flagInverter,
    });
  },
};
