<template>
  <v-app>
    <div
      class="tw-z-[9999999] tw-bg-white tw-w-screen tw-h-auto tw-bottom-0 tw-left-0 tw-fixed"
      v-if="getDeferred"
    >
      <div class="tw-p-4 tw-bg-gray-100">
        <div
          class="tw-absolute tw-cursor-pointer tw-right-5 tw--mt-8 tw-bg-gray-100 tw-rounded-full tw-border tw-border-gray-200 tw-w-7 tw-h-7 tw-justify-center tw-items-center tw-flex"
          @click="close"
        >
          <v-icon size="15">mdi-close</v-icon>
        </div>
        <div
          class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-[1fr_auto] tw-gap-2 tw-text-center tw-items-center"
        >
          <div>Você pode instalar o site no seu dispositivo agora mesmo</div>
          <div
            class="tw-grid tw-grid-cols-2 tw-gap-2 tw-text-center tw-my-3 sm:tw-my-0"
          >
            <div>
              <button
                class="tw-px-4 tw-py-3 tw-rounded tw-bg-primary hover:tw-opacity-95 tw-text-white tw-w-full"
                @click="install"
              >
                Instalar
              </button>
            </div>
            <div>
              <button
                class="tw-px-4 tw-py-3 tw-rounded tw-bg-gray-200 tw-w-full"
                @click="dismiss"
              >
                Agora não
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-notifys />
    <router-view />
  </v-app>
</template>

<script>
import Cookies from "js-cookie";
export default {
  name: "App",
  data() {
    return {
      deferredPrompt: null,
    };
  },
  mounted() {
    window.addEventListener("beforeinstallprompt", (e) => {
      if (Cookies.get("add-to-home-screen") === undefined) {
        this.deferredPrompt = e;
      }
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  computed: {
    getDeferred() {
      return this.deferredPrompt;
    },
  },
  methods: {
    close() {
      this.deferredPrompt = null;
    },
    async dismiss() {
      Cookies.set("add-to-home-screen", null, { expires: 15 });
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
  },
};
</script>

<style lang="scss">
html,
body,
* {
  font-family: "Manrope", sans-serif;
  margin: 0px;
  padding: 0px;
}
</style>
