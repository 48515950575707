<template>
  <div class="tw-flex tw-flex-row tw-items-center">
    <div class="tw-flex tw-flex-row tw-w-full tw-items-center tw-space-x-4">
      <div class="tw-flex-none">
        <slot name="left"></slot>
      </div>
      <div class="tw-flex-1 tw-px-3">
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
};
</script>

<style></style>
