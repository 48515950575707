import Vue from "vue";
import ErrorInput from "@/components/errorInput.vue";
import Notifys from "@/components/notify.vue";
import vLock from "@/components/inputLock.vue";
import vActionsDialog from "@/components/dialog/vActionsDialog.vue";
import vBtnDialogCancel from "@/components/dialog/vBtnDialogCancel.vue";
import vBtnDialogConfirm from "@/components/dialog/vBtnDialogConfirm.vue";
import vBtnContentHead from "@/components/vBtnContentHead.vue";

Vue.component("vActionsDialog", vActionsDialog);
Vue.component("vBtnDialogCancel", vBtnDialogCancel);
Vue.component("vBtnDialogConfirm", vBtnDialogConfirm);
Vue.component("vBtnContentHead", vBtnContentHead);
Vue.component("vLock", vLock);
Vue.component("v-error-input", ErrorInput);
Vue.component("v-notifys", Notifys);
