export default {
  badge: "Badge",
  close: "Fechar",
  dataIterator: {
    noResultsText: "Sem resultados na pesquisa",
    loadingText: "Carregando...",
  },
  dataTable: {
    itemsPerPageText: "Itens por página:",
    ariaLabel: {
      sortDescending: "Organizar decrescente",
      sortAscending: "Organizar crescente.",
      sortNone: "Não organizar",
      activateNone: "Activate to remove sorting.",
      activateDescending: "Activate to sort descending.",
      activateAscending: "Activate to sort ascending.",
    },
    sortBy: "Organizar por",
  },
  dataFooter: {
    itemsPerPageText: "Itens por pagina:",
    itemsPerPageAll: "Todos",
    nextPage: "Próxima página",
    prevPage: "Página anterior",
    firstPage: "Primeira Página",
    lastPage: "Ultima página",
    pageText: "{0}-{1} de {2}",
  },
  datePicker: {
    itemsSelected: "{0} selecionado",
    nextMonthAriaLabel: "Próximo mês",
    nextYearAriaLabel: "Próximo ano",
    prevMonthAriaLabel: "Mês anterior",
    prevYearAriaLabel: "Ano anterior",
  },
  noDataText: "Sem dados",
  carousel: {
    prev: "Previous visual",
    next: "Next visual",
    ariaLabel: {
      delimiter: "Carousel slide {0} of {1}",
    },
  },
  calendar: {
    moreEvents: "{0} more",
  },
  fileInput: {
    counter: "{0} files",
    counterSize: "{0} files ({1} in total)",
  },
  timePicker: {
    am: "AM",
    pm: "PM",
  },
  pagination: {
    ariaLabel: {
      wrapper: "Navegação de páginação",
      next: "Próxima página",
      previous: "Página anterior",
      page: "Para a pagina {0}",
      currentPage: "Página atual, pagina {0}",
    },
  },
  rating: {
    ariaLabel: {
      icon: "Rating {0} of {1}",
    },
  },
};
