import axios from "axios";

export default {
  getMonitoramento(sensorID, dataInicial, dataFinal) {
    return axios.post("/v1/coleta/dados-monitoramento", {
      dataInicial: dataInicial,
      dataFinal: dataFinal,
      sensorId: sensorID,
    });
  },
};
