<template>
  <div
    class="tw-fixed tw-z-[1000] tw-right-0 tw-top-0 tw-block tw-w-2/3 md:tw-w-1/3 tw-pr-1 tw-pt-1"
  >
    <span v-for="(item, index) in getNotifyList" :key="uuid(item)">
      <vNotify :item="item" :index="index" />
    </span>
  </div>
</template>

<script>
import vNotify from "@/components/notifyCard.vue";
export default {
  name: "notifyList",
  components: {
    vNotify,
  },
  computed: {
    getNotifyList() {
      return this.$store.getters["getNotifyList"];
    },
  },
  methods: {
    uuid(e) {
      if (e.uid) return e.uid;
      const key = Math.random().toString(16).slice(2);
      this.$set(e, "uid", key);
      return e.uid;
    },
  },
};
</script>

<style></style>
