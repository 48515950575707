export default [
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/dashboard/ViewDashboard.vue"),
    redirect: "/dashboard/sensores",
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("@/views/dashboard/home.vue"),
      },
      {
        path: "meu-perfil",
        name: "MeuPerfilDash",
        component: () => import("@/views/dashboard/MeuPerfil.vue"),
      },
      {
        path: "condominios",
        name: "Condominios",
        component: () =>
          import("@/views/dashboard/condominios/CondominiosIndex.vue"),
      },
      {
        path: "pre-cadastro-lote",
        name: "PreCadastroLoteSensor",
        component: () => import("@/views/dashboard/lotes/LotesSensorIndex.vue"),
      },
      {
        //todo: requer segurança
        path: "sensores-do-lote/:id",
        name: "SensoresDoLote",
        component: () => import("@/views/dashboard/lotes/SensoresDoLote.vue"),
      },
      {
        path: "sensor/:id",
        name: "sensorPorId",
        component: () =>
          import("@/views/dashboard/sensores/vMonitorSensor.vue"),
      },
      {
        path: "tipos-sensores",
        name: "DashTipoSensor",
        component: () =>
          import("@/views/dashboard/tipoSensor/TipoSensorIndex.vue"),
      },
      {
        path: "tipos-equipamentos",
        name: "DashTipoEquipamentos",
        component: () =>
          import("@/views/dashboard/equipamentos/EquipamentosIndex.vue"),
      },
      {
        path: "sensores",
        name: "sensores",
        component: () => import("@/views/dashboard/sensores/SensoresIndex.vue"),
      },
      {
        path: "ponto-de-instalacao",
        name: "ponto-de-instalacao",
        component: () =>
          import("@/views/dashboard/pontoInstalacao/PontoInstalacaoIndex.vue"),
      },
      {
        path: "clientes",
        name: "Clientes",
        component: () => import("@/views/dashboard/clientes/ClientesIndex.vue"),
      },
      {
        path: "usuarios",
        name: "usuarios",
        component: () => import("@/views/dashboard/usuarios/UsuariosIndex.vue"),
      },
    ],
  },
];
